import React, {Component} from 'react';

class RouterTest extends Component {

    render() {

        return (
            <h1>testing react router... </h1>
        )
    }
}

export default RouterTest;